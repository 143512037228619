.logo {
  width: 120px;
  transition: all 0.4s ease-in-out;
  color: #fff;
}

.logo-sub {
  width: 175px;
  transition: all 0.4s ease-in-out;
  color: #fff;
}

/*.logo-scrolled a img{
  width: 175px;
  transition: all 0.4s ease-in-out;
}*/

/* Mobile */
@media only screen and (max-width: 768px) {
  .logo {
    width: 120px;
    transition: all 0.4s ease-in-out;
    color: #fff;
  }

  /*.logo-scrolled a img{
    width: 200px;
    transition: all 0.4s ease-in-out;
  }

  .logo-sub {

  }*/
}
