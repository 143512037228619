.icon-wht {
  fill: #fff;
  stroke-width: 0;
}

.icon-gold {
  fill: url(#gold-gradient) #fff;
}

#gold-gradient {
  --color-stop: #f12c06;
  --color-bot: #faed34;
}

.icon-pink {
  fill: var(--pink);
  stroke-width: 0;
}

.icon-purple {
  fill: var(--purple);
  stroke-width: 0;
}

.icon-text-wht {
  fill: #fff;
  font-size: 24px;
}

.icon-sm {
  height: 14px;
}

.icon-md {
  height: 18px;
}

.icon-lg {
  height: 24px;
}

.icon-card-circle-sm {
  padding: 3px;
  height: 25px;
  width: 25px;
}

.icon-card-circle-md {
  padding: 3px;
  height: 36px;
  width: 36px;
}

.icon-card-circle-lg {
  padding: 3px;
  width: 48px;
  height: 48px;
}

.icon-container {
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-container a {
  padding: 0;
}

.icon-card-circle-sub {
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}