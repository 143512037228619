/*
* Custom styles to override Formik
*/

.login-box {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../../assets/bg/auth_bg_dk_v3.2.jpg') center center no-repeat;
  overflow: auto;
}

.auth-logo {
  width: 10rem;
  margin-bottom: 20px;
}

.container-auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.container-auth-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px;
  background-color: rgba(255,255,255, 0.1);
  border: 1px solid rgba(255,255,255, 0.5);
  border-radius: 10px;
  backdrop-filter: blur(5px);
}

.auth-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.auth-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  width: 100%;
}

.auth-col-title {
  margin-bottom: 20px;
}

.auth-col div input {
  color: rgba(255,255,255, 0.75);
  background-color: rgba(255,255,255, 0.05) !important;
  border-radius: 3px !important;
  border: 1px solid rgba(255,255,255, 0.25) !important;
}

.auth-col div input {
  padding: 5px 10px;
  margin-top: 5px;
}

.auth-col div input:focus {
  outline: none;
}

.auth-label, .invalid-feedback {
  font-size: 14px;
}

.invalid-feedback {
  color: var(--light-green);
  margin-top: 5px;
}

.error {
  display: none;
}

.auth-login-redirects a {
  padding-left: 10px;
}

.login-box a {
  text-decoration: underline !important;
}

/* Mobile Stuff */
@media only screen and (max-width: 768px) {
  .container-auth {
    margin: 2.5vw
  }

  .container-auth-card {
    justify-content: center;
    padding: 5vw;
  }

  .form-auth {
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .auth-row {
    flex-wrap: wrap;
    margin-bottom: 5px;
    padding: 0;
  }

  .auth-col {
    padding: 0 0 10px 0;
  }

  .auth-col div {
    width: 100%;
  }

  .auth-col div input {
    width: 80vw;
    padding: 10px;
  }

  .auth-btn-row {
    margin-top: 20px;
  }
}