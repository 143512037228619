.dashboard-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100vw;
  height: 100vh;
  padding: 0;
}

.dashboard-content-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 82vh;
  padding: 0;
  top: 10vh;
}

@media only screen and (max-width: 768px) {
  .dashboard-content-container {
    flex-direction: column;
  }
}
