.dropdown-list-task {
  display: flex;
  flex-direction: column;
  width: 25vw;
  padding-bottom: 20px;
}

.dropdown-list-task label {

}

.prompt-wrapper h3 {
  padding-bottom: 10px;
}

.prompt-library-content ul li {
  display: flex;
  flex-direction: column;
  height: 60vh;
  width: 100%;
  overflow: auto;
}

.prompt-library-content ul li p {
  margin-bottom: 20px;
}

.prompt-library-content ul li button {
  padding: 5px 0;
  margin: 0 0 20px 0;
  width: 120px;
  border-radius: 4px;
  border: 1px solid var(--dk-grey);
  background-color: var(--dk-grey);
  color: #f1f1f1;
}

.prompt-library-content ul li button:hover {
  color: var(--pink);
}

.copy-btn {
  padding: 20px 0;
}

@media only screen and (max-width: 768px) {

  .prompt-library .content-title h1 {
    padding: 40px 0 10px;
  }

  .prompt-library-content {
    padding: 0;
  }

  .dropdown-list-task {
    align-items: flex-start;
    width: 75vw;
    padding: 0 0 20px;
    margin: 0;
  }

  .prompt-library-content ul li button {
    margin: 0 0 20px 10px;
  }
}