.sidebar-wrapper-container {
  position: relative;
  background-color: var(--dk-grey);
  color: #f1f1f1;
  background-image: url('../../../assets/sidebar-tech-300x1450.jpg');
  background-position: top;
  background-size: cover;
  width: 12vw;
  height: 82vh;
  padding: 0 20px;
  top: 0;
}

.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 82vh;
  padding: 0;
}

.sidebar-title {
  padding-top: 40px;
}

.sidebar-title h4 {
  padding-bottom: 10px;
}

.sidebar-list li {
  padding: 5px 0;
}

.sidebar-list li:hover {
  cursor: pointer;
}

.sidebar-disclaimer {
  padding-bottom: 20px;
}

.sidebar-disclaimer-mobile {
  display: none;
}

.sidebar ul li {
  font-size: 14px;
}

.sidebar-link {
  padding-bottom: 15px;
}

.dropdown-list-department {
  display: flex;
  flex-direction: column;
}

.dropdown-list-department label {
  padding-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .sidebar-wrapper-container {
    padding: 0;
    width: 100vw;
    height: unset;
    background-image: url('../../../assets/sidebar-mobile-390x250.jpg');
    background-position: center;
    background-size: cover;
  }

  .sidebar-title {
    padding: 20px 10px 0;
  }

  .sidebar-disclaimer {
    display: none;
  }

  .sidebar-disclaimer-mobile {
    display: contents;
  }

  .sidebar-disclaimer-mobile span {
    line-height: 2;
  }

  .sidebar-wrapper {
    padding: 10px 0 20px;
  }

  .sidebar h2 {
    padding: 10px 20px;
  }
}