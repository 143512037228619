/* Weights/Sizes */
.btn-light {
  font-weight: 200;
}

/* Colors/BG */
.btn-hollow {
  font-size: 12px;
  border-radius: 0;
  border: 1px solid var(--light-grey);
  background-color: transparent;
}

.btn-hollow-sm {
  font-size: 14px;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 0;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.btn-transparent {
  font-size: 12px;
  border-radius: 0;
  border: none;
  background-color: transparent;
}

.btn-ghost {
  background-color: rgba(255,255,255, 0.15);
  border: 1px solid rgba(255,255,255, 0.25);
  color: var(--off-white);
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 15px 0 0;
}

.btn-ghost:hover {
  background-color: rgba(255,255,255, 0.2);
}

.btn-cta {
  font-size: 18px;
  padding: 20px 40px;
  margin-top: 20px;
  border-radius: 10px;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.btn-cta:hover {

}

.btn-cta-sub {
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 0;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* Input Stuff */

.ghost-input {
  background-color: rgba(255,255,255, 0.15);
  border-radius: 3px;
  border: 1px solid rgba(255,255,255, 0.25);
  padding: 5px 10px;
}

/* Mobile Stuff */
@media only screen and (max-width: 768px) {
  /* Misc. */
  .wrapper-cta-center-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}