.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 86vh;
  padding-top: 7vh;
  text-align: center;
  line-height: 1.5;
}

.not-found h1 {
  font-size: 36px;
}

.not-found p {
  font-size: 24px;
}

.not-found p a {
  font-weight: 700;
}