.tethyr-roadmap-svg-left {
  position: absolute;
  top: 200px;
  left: 0;
}

.tethyr-roadmap-svg-right {
  position: absolute;
  top: 260px;
  right: 0;
  rotate: 180deg;
}

.section-product-roadmap {
  position: relative;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 180px 0 80px;
}

.tethyr-roadmap-banner-img {
  padding-bottom: 40px;
}

.tethyr-roadmap-banner-img-mobile {
  display: none;
}

.product-roadmap-intro {
  text-align: center;
  max-width: 640px;
}

.product-roadmap-intro h2 {
  padding: 0 0 40px;
}

.product-roadmap-intro .product-roadmap-callout {
  padding-top: 40px;
}

p.product-roadmap-callout {
  font-size: 24px;
}

.product-roadmap-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 45px;
  padding: 40px 0 0;
}

@media screen and (max-width: 767px) {
  .section-product-roadmap {
    padding-top: 80px;
  }

  .tethyr-roadmap-svg-left, .tethyr-roadmap-svg-right {
    display: none;
  }

  .tethyr-roadmap-banner-img {
    display: none;
  }

  .tethyr-roadmap-banner-img-mobile {
    max-width: 100vw;
    display: block;
    padding: 0 0 40px;
  }

  .product-roadmap-intro {
    padding: 0 20px;
  }

  .product-roadmap-cards {
    padding: 40px 0 0;
  }
}
