/* Toggle Status */
.hide {
  visibility: hidden;
}

.active, .active button{
  color: var(--light-grey) !important;
  font-weight: bold;
}

/* Scrollbar Stuff */
/* Width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3c3c3c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Transitions */
@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(-15px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(0);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(0);
  }
}

/*.agent-1, .agent-3 {
  opacity: 0;
  animation: fade-in-right ease 0.4s forwards;
}*/

@keyframes grow-left {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

/* Usage
.agent-2 {
  transform: scaleX(0);
  transform-origin: left;
  animation: grow-left cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s forwards;
  animation-delay: 0.4s;
}
*/

@media only screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 0;
  }

  .active, .active button{
    border-radius: 0 !important;
  }
}