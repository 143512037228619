.section-meet-tethyr {
  position: relative;
  top: 0;
  width: 100%;
  padding: 200px 0 100px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  background-color: var(--white);
  background: url("../../../assets/bg/meet-tethyr-bg_1700x715.jpg") right bottom no-repeat;
  background-size: cover;
}

.content-meet-tethyr {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  max-width: 650px;
  padding-left: 250px;
}

.content-meet-tethyr h3 {
  font-size: 34px;
  color: var(--dk-grey-2);
  padding-bottom: 20px;
}

.content-meet-tethyr .tethyr-title {

}

.content-meet-tethyr p {
  font-weight: 500;
  font-size: 20px;
  padding: 10px 0 20px;
}

.content-meet-tethyr p.paragraph-dk {

}

.content-meet-tethyr p.paragraph-dk-sub {
  padding-top: 10px;
}

.tethyr-visuals {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tethyr-visuals .meet-tethyr {
  max-width: 300px;
  padding: 100px 120px 0 0;
}

.tethyr-visuals .tethyr-plushie {
  max-width: 600px;
}

.tethyr-plushie-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .section-meet-tethyr {
    flex-wrap: wrap-reverse;
    background: none;
    padding: 80px 0 80px;
    text-align: center;
  }

  .content-meet-tethyr {
    padding: 0 20px;
  }

  .content-meet-tethyr h3 {
    align-self: center;
    padding: 20px 0;
  }

  .content-meet-tethyr .tethyr-title {

  }

  .content-meet-tethyr p {
    font-size: 18px;
  }

  .content-meet-tethyr p.paragraph-dk {

  }

  .content-meet-tethyr p.paragraph-dk-sub {
  }

  .tethyr-callout {
    padding-top: 40px;
  }

  .tethyr-visuals {

  }

  .tethyr-visuals .meet-tethyr {
    display: none;
  }

  .tethyr-visuals .tethyr-plushie {
    max-width: 100vw;
  }

  .tethyr-plushie-mobile {
    display: block;
  }
}
