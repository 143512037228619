.container-pill-hollow {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 25px;
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0), 0px -10px 25px rgba(255, 255, 255, 0), inset 0px -10px 20px rgba(255, 255, 255, 0.15), inset 0px 15px 20px rgba(255, 255, 255, 0.15);
  padding: 10px 30px;
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}