/* Fill/Stroke Stuff */
.fill-pink {
  fill: var(--pink);
}

.fill-lt-green {
  fill: rgba(0, 251, 147, 0.1);
}

.fill-lt-blue {
  fill: rgba(0, 195, 255, 0.1);
}

.fill-grey {
  fill: var(--mid-grey-2);
}

.fill-dark {
  fill: #3a3a3a;
}

.even-odd {
  fill-rule: evenodd;
}

.opacity-half {
  opacity: .5;
}

.opacity-quarter {
  opacity: .25;
}

.stroke-0 {
  stroke-width: 0;
}

.stroke-2 {
  stroke-width: 2px;
}

.drop-shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.drop-shadow-lifted {
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) , 0 0 40px rgba(0, 0, 0, 0.1) inset;
  -mox-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) , 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) , 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.lifted {
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.lifted:before,
.lifted:after {
  bottom: 15px;
  -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
  -mox-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.lifted:after {
  right: 10px;
  left: auto;
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  transform: rotate(3deg);
}

/* BG Colors */
.bg-slanted-gradient-purple {
  background-color: var(--pink);
  background-image: linear-gradient(134deg, var(--pink) 0%, var(--purple) 100%);
}

.bg-straight-gradient-purple {
  background-color: var(--pink);
  background-image: linear-gradient(90deg, var(--pink) 0%, var(--purple) 100%);
}

.bg-slanted-gradient-pink {
  background-color: var(--mid-pink);
  background-image: linear-gradient(134deg, var(--mid-pink) 0%, var(--bright-pink) 100%);
}

.bg-straight-gradient-pink {
  background-color: var(--mid-pink);
  background-image: linear-gradient(90deg, var(--mid-pink) 0%, var(--bright-pink) 100%);
}

.bg-slanted-gradient-light-pink {
  background-color: var(--light-pink-2);
  background-image: linear-gradient(134deg, var(--light-pink-2) 0%, var(--mid-pink-2) 100%);
}

.bg-straight-gradient-light-pink {
  background-color: var(--light-pink-2);
  background-image: linear-gradient(90deg, var(--light-pink-2) 0%, var(--mid-pink-2) 100%);
}

.bg-slanted-gradient-blue {
  background-color: var(--light-blue);
  background-image: linear-gradient(134deg, var(--light-blue) 0%, var(--blue) 100%);
}

.bg-straight-gradient-blue {
  background-color: var(--light-blue);
  background-image: linear-gradient(90deg, var(--light-blue) 0%, var(--blue) 100%);
}

.bg-slanted-gradient-green {
  background-color: var(--light-green);
  background-image: linear-gradient(134deg, var(--light-green) 0%, var(--green) 100%);
}

.bg-straight-gradient-green {
  background-color: var(--green);
  background-image: linear-gradient(90deg, var(--green) 0%, var(--light-green) 100%);
}

.bg-slanted-gradient-orange {
  background-color: var(--yellow);
  background-image: linear-gradient(134deg, var(--yellow) 0%, var(--orange) 100%);
}

.bg-straight-gradient-orange {
  background-color: var(--yellow);
  background-image: linear-gradient(90deg, var(--yellow) 0%, var(--orange) 100%);
}

.bg-slanted-gradient-yellow {
  background-color: var(--light-yellow);
  background-image: linear-gradient(134deg, var(--light-yellow) 0%, var(--yellow) 100%);
}

.bg-straight-gradient-yellow {
  background-color: var(--light-yellow);
  background-image: linear-gradient(90deg, var(--light-yellow) 0%, var(--yellow) 100%);
}

.bg-straight-gradient-black {
  background-color: var(--mid-blk);
  background-image: linear-gradient(90deg, var(--mid-blk) 0%, var(--mid-grey-3) 100%);
}

.bg-gold {
  background-color: var(--mid-grey-3);
  background-image: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}

.bg-clear {
  background: transparent;
}

.bg-transparent {
  background: rgba(255, 255, 255, 0.05);
}

.bg-transparent-dk {
  background: rgba(0, 0, 0, .1);
}

.bg-frost {
  background: var(--transparent-white);
}

.bg-white {
  background-color: var(--white);
}

.bg-off-white {
  background-color: var(--off-white-2);
}

.bg-dk {
  background-color: var(--mid-blk);
}

.bg-lt-pink {
  background-color: var(--light-pink);
}

/* Font Colors */
.text-lt {
  color: var(--off-white-2);
}

.text-dk {
  color: var(--dk-grey);
}

.text-purple {
  color: var(--purple);
}

.text-blue {
  color: var(--light-blue);
}

.text-green {
  color: var(--green);
}

.text-lt-green {
  color: var(--light-green);
}

.text-orange {
  color: var(--orange);
}

.text-gradient-purple {
  background: -webkit-linear-gradient(var(--pink), var(--purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-gold {
  /*#FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%*/
  background: -webkit-linear-gradient(#FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-blue {
  background: -webkit-linear-gradient(var(--light-blue), var(--blue));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-green {
  background: -webkit-linear-gradient(var(--light-green), var(--green));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-orange {
  background: -webkit-linear-gradient(var(--yellow), var(--orange));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Fields */
.label-pad {
  padding-bottom: 10px;
}

.dropdown-dk-purple {
  outline: none;
  border-radius: 6px;
  border: 1px solid var(--purple);
  background-color: var(--dk-grey);
  color: var(--white);
  padding:  5px 10px;
}

.dropdown-mid-purple {
  outline: none;
  border-radius: 6px;
  border: 1px solid var(--purple);
  background-color: var(--dk-grey-2);
  color: var(--white);
  padding:  5px 10px;
}

.border-purple {
  outline: none;
  border-radius: 6px;
  border: 1px solid var(--purple);
}