/* Root Variables */
:root {
  --purple: #8705e4;
  --pink: #b700ff;
  --mid-pink: #ff7bb7;
  --mid-pink-2: #fe91c6;
  --bright-pink: #ff006a;
  --light-pink: #fdf5ff;
  --light-pink-2: #fed0c7;
  --light-pink-3: #fbf3fd;
  --yellow: #ff9d00;
  --light-yellow: #f9ea33;
  --orange: #f04636;
  --dk-blue: #020025;
  --blue: #0177fe;
  --light-blue: #00c3ff;
  --light-green: #00FB93;
  --green: #00d37d;
  --transparent-white: rgba(255, 255, 255, 0.2);
  --white: #ffffff;
  --off-white: #f1f1f1;
  --off-white-2: #fdf5ff;
  --light-grey: #f8f9fa;
  --mid-grey: #666666;
  --mid-grey-2: #cccccc;
  --mid-grey-3: #3f3f3f;
  --dk-grey: #222222;
  --dk-grey-2: #333333;
  --transparent-blk: rgba(250, 250, 250, 0.05);
  --mid-blk: #1b1b1b;
  --dk-blk: #000000;
}