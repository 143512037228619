.content-box-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(88vw - 80px);
  height: 82vh;
  padding: 0 40px;
  overflow-y: scroll;
  background-color: var(--dk-grey);
}

.content-box-wrapper-container {
  position: absolute;
  height: 100%;
  padding: 0;
}

.content-title h1 {
  font-size: 20px;
  color: var(--white);
  margin: 0;
  padding: 40px;
}

.content-wrapper {
  padding: 0 40px 40px;
}

@media only screen and (max-width: 768px) {
  .content-box-wrapper-container {
    top: unset;
    height: 100%;
    width: 100%;
  }

  .content-box-wrapper {
    width: 100%;
    padding: 0;
  }

  .content {
    padding: 0 10px 20px;
  }

  .content-title {
    padding: 0 10px;
  }

  .content-title h1 {
    text-align: left;
    padding: 40px 0 20px !important;
  }

  .content-wrapper {
    padding: 0;
  }

  .content-box {
    width: 100%;
    padding: 0;
  }
}