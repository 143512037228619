@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import "./styles/imports.css";

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  line-height: 1.25;
  color: var(--dk-grey-2);
}

a {
  text-decoration: none;
  color: inherit;
}

main {
  padding: 0;
}

p, ul, li, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

select:hover, option:hover, input[type='submit']:hover, button, .link {
  cursor: pointer;
}

/* Font Size Stuff */
.title-main {
  font-weight: 700;
  line-height: 0.8;
}

.title-sub-1 {
  font-size: 32px;
  font-weight: 700;
}

/* Used for Cards, etc. */
.title-sub-2 {
  font-size: 24px;
  font-weight: 600;
}

.title-sub-3 {
  font-size: 18px;
  font-weight: 600;
}

.title-sub-4 {
  font-size: 14px;
  font-weight: 400;
}

.paragraph-sub-2 {
  font-size: 22px;
  font-weight: 400;
}

.paragraph-sub-3 {
  font-size: 18px;
  font-weight: 400;
}

.paragraph-sub-4 {
  font-size: 14px;
  font-weight: 200;
}

.paragraph-sub-5 {
  font-size: 12px;
  font-weight: 200;
}

.paragraph-lt {
  font-size: 16px;
  font-weight: 300;
  color: var(--white);
}

.paragraph-dk {
  font-size: 16px;
  font-weight: 300;
  color: var(--mid-grey);
}

.text-shrink-md {
  font-size: medium !important;
}

.text-shrink-sm {
  font-size: small !important;
}

/* Used for Cards, etc. */
.paragraph-lt-sub {
  font-size: 14px;
  font-weight: 300;
  color: var(--white);
}

.paragraph-dk-sub {
  font-size: 14px;
  font-weight: 300;
  color: var(--mid-grey);
}

.paragraph-section {
  padding: 20px 0;
}

.paragraph-section h3 {
  padding-bottom: 5px;
}

/* Font Variants */
.cite {
  text-align: right;
  font-style: italic;
  font-size: smaller;
}

.italic {
  font-style: italic;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.light-bold {
  font-weight: 400;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 900;
}

.strike-through {
  text-decoration: line-through;
}

/* Image Stuff */
.wrapper-header-logo {
  /*min-width: 250px;*/
}

/* Misc. */
.flex-start-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.no-outline {
  outline: none;
}

.no-border {
  border: none;
}

.divider {
  width: calc(100vw - 260px);
  border-bottom: 2px solid rgba(255, 255, 255, 0.20);
}

.content-page {
  padding: 10vh 0 8vh;
  height: 82vh;
}

.content-page-wrapper {
  padding: 40px 40px 80px;
  overflow-y: auto;
}

@media only screen and (max-width: 768px) {
  /* Font Size Stuff - Mobile */
  .title-main {
    font-size: 32px;
    font-weight: 900;
    line-height: 1.1;
  }

  .title-sub-1 {
    font-size: 24px;
  }

  .title-sub-2 {
    font-size: 18px;
  }

  /* Misc */
  .divider {
    width: calc(100vw - 40px);
    border-bottom: 2px solid rgba(255, 255, 255, 0.20);
  }
}