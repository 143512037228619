/* Hero FactSection */
.section-hero {
  position: relative;
  top: 0;
  background: url('../../../assets/bg/auth_bg_dk_v3.1.jpg') center center fixed no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--white);
}

.title-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  padding: 0 0 0 60px;
}

.title-hero {
  font-size: 10vw;
  line-height: .7;
  opacity: .25;
}

.title-hero-1 {
  font-size: 7vw;
  margin-left: 80px;
}

.title-hero-1-sub {
  font-size: 4vw;
}

.title-hero-2 {
  font-size: 9vw;
}

.title-hero-3 {
  font-size: 7vw;
  margin-left: 60px;
}

.title-hero-4 {
  margin-left: 20px;
  font-size: 9vw;
}

.title-hero-4-sub {
  font-size: 4vw;
}

.title-mobile {
  display: none;
}

.hero-cta {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.hero-cta button svg {
  transform: rotate(90deg);
}

.hero-cta button {
  padding: 10px 15px;
  margin: 60px 0 0 60px;
  border-radius: 10px;
  color: rgba(255,255,255,.5);
  border: 1px solid rgba(255,255,255,.5);
}

.hero-cta button svg {
  fill: var(--green);
  fill-opacity: .85;
}

.hero-icon {
  font-size: 2vw;
}

.hero-icon-center {
  font-size: 4vw;
}

.content-hero {
  padding-bottom: 20px
}

/* Hero FactSection Max 767px Smartphone */
@media screen and (max-width: 767px) {
  .section-hero {
    background: url('../../../assets/bg/contact-tech-bg_400x800.jpg') center center no-repeat;
    background-size: cover;
    justify-content: center;
    align-items: center;
  }

  .title-main {
    font-size: 90vw;
    padding: 0;
    align-items: center;
    gap: 30px;
  }

  .title-hero {
    line-height: .7;
    opacity: .25;
  }

  .title-hero-1 {
    font-size: 12vw;
    margin-left: 60px;
  }

  .title-hero-1-sub {
    font-size: 12vw;
  }

  .title-hero-2 {
    font-size: 14vw;
  }

  .title-hero-3 {
    font-size: 11vw;
    margin-left: -60px;
  }

  .title-hero-4 {
    font-size: 17vw;
    margin-left: 0;
  }

  .title-hero-4-sub {
    font-size: 4vw;
  }

  .hero-cta {
    align-items: center;
    justify-content: center;
  }

  .hero-cta button {
    margin: 120px 0 0 0;
  }

}