.section-header-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  height: 10vh !important;
  display: flex;
  align-items: center;
  transition: background 0.4s ease;
  padding: 0;
  z-index: 1000;
}

#wrapper-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.wrapper-header-links nav button {

}

header.scrolled {
  transition: background 0.4s ease;
  background: rgba(0, 0, 0, 0.9);
}

.nav-item {
  font-size: 12px !important;
}

.nav-item a button {
  padding: 5px 15px;
}

.nav-item a button:hover {
  transition: color 0.3s ease-in-out;
  color: var(--off-white-2);
}

.navigation {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-right: 0 !important;
}

.navigation.close {
  display: none;
}

.navigation.open {
  position: absolute;
  right: 0;
  top: 10vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 0;
  animation: fade-in ease-in-out 0.7s forwards;
}

.wrapper-header-cta {
  display: none;
}

@media (min-width: 768px) {
  .header-padding {
    padding: 0;
  }

  .navigation {
    display: flex !important;
    margin-right: 40px !important;
  }

  .hamburger-react {
    display: none !important;
  }

  #wrapper-header {
    padding: 0 40px;
  }

  .wrapper-header-cta {
    display: flex;
    border-radius: 10px;
  }

  .wrapper-header-cta button {
    border-radius: 10px;
    color: rgba(0,0,0,.6);
  }

  .wrapper-header-cta button svg {
    fill: rgb(0,0,0);
    fill-opacity: .6;
  }

  .header-cta-icon {
    color: rgba(0,211,125,.5);
  }
}
