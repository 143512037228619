/* Base styles for the label container */
.form-checkbox-label-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

/* Styles for the actual checkbox (hidden) */
.form-checkbox-label-container input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Styles for the custom checkbox */
.form-checkbox-label-container input[type="checkbox"] + .check-span::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 2px solid var(--transparent-white);
  background-color: var(--transparent-blk);
  border-radius: 3px;
  margin-right: 8px;
  vertical-align: middle;
  text-align: center;
  transition: background-color 0.2s, border-color 0.2s;
}

/* Styles for the custom checkbox when checked */
.form-checkbox-label-container input[type="checkbox"]:hover + .check-span::before {
  border-color: var(--purple);
  position: relative;
}

/* Styles for the custom checkbox when checked */
.form-checkbox-label-container input[type="checkbox"]:checked + .check-span::before {
  background-color: var(--transparent-white);
  border-color: var(--purple);
  position: relative;
}

/* Checkmark for checked state */
.form-checkbox-label-container input[type="checkbox"]:checked + .check-span::after {
  content: "\2713"; /* Unicode character for a checkmark */
  position: absolute;
  font-size: 10px;
  color: white;
  top: 55%;
  left: 5px;
  transform: translateY(-50%);
}

/* Styles for the text label */
.check-span {
  position: relative;
  vertical-align: middle;
}