.tethyr-features-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  max-width: 450px;
  height: 100px;
  border-radius: 16px;
  border: 1px solid rgba(255,255,255,0.25);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin: 20px;
  padding: 40px;
  text-align: left;
}

.tethyr-icon {
  width: 22px;
}

.tethyr-icon-md {
  width: 16px;
}

.tethyr-features-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.tethyr-features-content {
  font-size: 14px;
}

@media only screen and (max-width: 768px) {

}