.section-landing-links {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  padding: 0;
}

.container-landing-links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  padding: 120px 0;
}

.section-landing-links h4, .section-landing-links a {
  padding-bottom: 10px;
}

.wrapper-landing-links-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  max-width: 300px;
}

.wrapper-landing-links-logo img {
  padding-bottom: 10px;
}

.wrapper-landing-links-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.wrapper-landing-links-company {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.wrapper-landing-links-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.wrapper-landing-links-contact a {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

@media only screen and (max-width: 768px) {
  .section-landing-links {
    justify-content: center;
    align-items: center;
  }

  .container-landing-links {
    flex-wrap: wrap;
    justify-content: center;
    padding: 80px 0;
    gap: 80px;
  }

  .wrapper-landing-links-links {
    display: none;
  }

  .wrapper-landing-links-logo, .wrapper-landing-links-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .wrapper-landing-links-logo img {
    max-width: 80vw;
    padding-bottom: 10px;
  }

  .wrapper-footer-right {
    padding-top: 20px;
  }
}