.section-footer {
  position: absolute;
  background: transparent;
  width: 100%;
  padding: 0;
}

.section-footer-fixed {
  position: fixed !important;
  bottom: 0;
  height: 8vh;
}

.section-footer {
  position: relative;
}

.wrapper-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
}

.wrapper-footer a {
  padding: 0;
}

.wrapper-footer-left a:first-child {
  padding-right: 10px;
}

.wrapper-footer-center {

}

.wrapper-footer-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}