.tethyr-intro-svg-left {
  position: absolute;
  top: 200px;
  left: 0;
}

.tethyr-intro-svg-right {
  position: absolute;
  top: 260px;
  right: 0;
  rotate: 180deg;
}

.tethyr-intro {
  position: relative;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--mid-blk);
  color: var(--light-grey);
  padding: 0 0 80px;
}

.tethyr-banner-img-mobile {
  display: none;
}

.tethyr-intro-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.tethyr-intro-content img {
  padding: 40px 0;
  width: 250px;
  max-width: 250px;
;
}

.tethyr-intro-content h3 {
  padding-top: 40px;
}

.tethyr-intro-content p {
  max-width: 650px;
  padding-left: 20px;
  padding-right: 20px;
}

.tethyr-feature-callout {
  padding: 60px 0 20px;
}

.wrapper-what-to-do-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 0;
}

.wrapper-what-to-do-bottom p {

}

@media only screen and (max-width: 768px) {
  .section-what-to-do {
    padding: 180px 0 80px;
  }

  .tethyr-banner-img {
    display: none;
  }

  .tethyr-banner-img-mobile {
    max-width: 100vw;
    display: block;
    padding-top: 80px;
  }

  .tethyr-intro-svg-left, .tethyr-intro-svg-right {
    display: none;
  }

  .tethyr-intro-right {
    display: none;
  }
}
