.section-landing-footer {
  position: relative;
  top: 0;
  background-image: url('../../../assets/bg/auth_bg_dk_v3.2.jpg');
  background-position: top;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 120px 0 0;
}

@media only screen and (max-width: 768px) {
  .section-landing-footer {
    background-image: url('../../../assets/bg/contact-tech-bg_400x800.jpg');
    background-position: top;
    padding-left: 0;
    padding-right: 0;
  }

  .container-landing-footer {
    padding: 0 20px;
  }
}