.product-roadmap-card {
  border-radius: 16px;
  max-width: 375px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0 20px;
  background-color: var(--dk-blk);
}

.product-roadmap-card-wrapper {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.product-roadmap-card-wrapper h3 {
  padding: 20px 0;
}

.product-roadmap-card-link {
  margin: 40px 0 0;
  align-self: center;
}

@media screen and (max-width: 767px) {

}